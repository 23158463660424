import React from 'react';
import { t, defineMessages } from '@sm/intl';
import EndpageHeader from '~app/components/EndpageHeader';
import { COPY as SIGN_UP_COPY } from '~app/components/RebrandEpFormCore/index';
import { COPY as PRODUCT_COPY } from '~app/pages/SurveyThanks/RebrandEpForm/index';
import FortuneCallout from '~app/components/FortuneCallout/FortuneCallout';
import SplitHero from '~app/components/SplitHero';

export const DARE_TO_ASK_COPY = defineMessages({
  STATISTIC_HEADER: {
    id: 'DareToAsk.StatisticHeader',
    defaultMessage: "We answer 20M questions a day.<br/>What's yours?",
    description: '[Type: label][Vis: high] - A Header to encourage users to sign up',
  },
  STATISTIC_SUBHEADER: {
    id: 'DareToAsk.StatisticSubHeader',
    defaultMessage:
      'SurveyMonkey helps answer 20M questions a day, from more than 300,000 global organizations. If you care enough to ask, we’re here to make it easy.',
    description: '[Type: label][Vis: low] - A sub-header to encourage users to sign up',
  },
  PROVOCATIVE_HEADER: {
    id: 'DareToAsk.ProvocativeHeader',
    defaultMessage: 'Dare to ask the questions that matter',
    description: '[Type: label][Vis: high] - A Header to encourage users to sign up',
  },
  PROVOCATIVE_SUBHEADER_SIGNUP: {
    id: 'DareToAsk.ProvocativeSubHeaderSignUp',
    defaultMessage:
      'Caring about your customers and employees means daring to ask the questions that matter. Create an account and start getting insights in as little as an hour.',
    description: '[Type: label][Vis: low] - A sub-header to encourage users to sign up',
  },
  PROVOCATIVE_SUBHEADER_LEARN_MORE: {
    id: 'DareToAsk.ProvocativeSubHeaderLearnMore',
    defaultMessage:
      'Caring about your customers and employees means daring to ask the questions that matter. See how to get the insights you need to drive your business forward.',
    description: '[Type: label][Vis: low] - A sub-header to encourage users to sign up',
  },
  ALT_FORTUNE_CALLOUT_COPY: {
    id: 'DareToAsk.FortuneCalloutCopy',
    defaultMessage: '95% of the Fortune 500 rely on SurveyMonkey',
    description: '[Type: Header][Vis: low] - A promotional statistic designed to show corporate trust and prestige',
  },
});

const DARE_TO_ASK_V2_PRACTICAL_COPY = {
  header: 'Check out workplace trends to watch',
  subHeader:
    'From work-life balance to employee confessions, SurveyMonkey has the latest data on what’s really going on in today’s workplace.',
};

const DARE_TO_ASK_V2_ZESTY_COPY = {
  header: 'Discover how work culture is changing',
  subHeader:
    'Quiet quitting is out; quiet vacationing is in. Find out how younger generations are reshaping work and see our latest insights.',
};

type copyObject = { [key: string]: string };

function DareToAsk({
  utSource2 = 'DareToAskCTA1',
  splitHeroTextCentered = false,
  splitHeroDarkBackground = false,
  splitHeroCTA = false,
  splitHeroPrimaryCTA = '',
  splitHeroSecondaryCTA = '',
  splitHeroImage = 'dareToAskText',
  fortuneBannerDarkBackground = false,
  fortuneBannerLogoWall = false,
  fortuneBannerCTA = false,
}: {
  utSource2: string;
  splitHeroTextCentered?: boolean;
  splitHeroDarkBackground?: boolean;
  splitHeroCTA?: boolean;
  splitHeroPrimaryCTA?: string;
  splitHeroSecondaryCTA?: string;
  splitHeroImage: string;
  fortuneBannerDarkBackground?: boolean;
  fortuneBannerLogoWall?: boolean;
  fortuneBannerCTA?: boolean;
}): React.ReactElement {
  const utSource = 'survey-thanks';

  const boldHeaderMapping: copyObject = {
    DareToAskCTA2: t(DARE_TO_ASK_COPY.PROVOCATIVE_HEADER),
    DareToAskZesty: DARE_TO_ASK_V2_ZESTY_COPY.header,
    DareToAskPractical: DARE_TO_ASK_V2_PRACTICAL_COPY.header,
    DareToAskZestyUk: DARE_TO_ASK_V2_ZESTY_COPY.header,
    DareToAskPracticalUk: DARE_TO_ASK_V2_PRACTICAL_COPY.header,
    DareToAskV3SignUp: DARE_TO_ASK_V2_PRACTICAL_COPY.header,
    DareToAskCreate: DARE_TO_ASK_V2_PRACTICAL_COPY.header,
  };
  const boldHeader = boldHeaderMapping[utSource2] ?? t(DARE_TO_ASK_COPY.STATISTIC_HEADER, {}, { html: true });

  const subHeaderMapping: copyObject = {
    DareToAskCTA2: t(DARE_TO_ASK_COPY.STATISTIC_SUBHEADER),
    DareToAskSignUp: t(DARE_TO_ASK_COPY.PROVOCATIVE_SUBHEADER_SIGNUP),
    DareToAskZesty: DARE_TO_ASK_V2_ZESTY_COPY.subHeader,
    DareToAskPractical: DARE_TO_ASK_V2_PRACTICAL_COPY.subHeader,
    DareToAskZestyUk: DARE_TO_ASK_V2_ZESTY_COPY.subHeader,
    DareToAskPracticalUk: DARE_TO_ASK_V2_PRACTICAL_COPY.subHeader,
    dareToAskV3SignUp: DARE_TO_ASK_V2_PRACTICAL_COPY.subHeader,
    dareToAskCreate: DARE_TO_ASK_V2_PRACTICAL_COPY.subHeader,
  };
  const subHeader = subHeaderMapping[utSource2] ?? t(DARE_TO_ASK_COPY.PROVOCATIVE_SUBHEADER_LEARN_MORE);

  const FortuneBannerCopy = [
    'DareToAskZesty',
    'DareToAskPractical',
    'DareToAskPracticalUk',
    'DareToAskZestyUk',
    'DareToAskV3SignUp',
    'DareToAskCreate',
  ].includes(utSource2)
    ? t(DARE_TO_ASK_COPY.ALT_FORTUNE_CALLOUT_COPY)
    : t(SIGN_UP_COPY.FORTUNE_CALLOUT_COPY);

  const HERO_FORM_CTA = {
    ctaFormEmail: t(SIGN_UP_COPY.HERO_FORM_CTA_EMAIL_ADDRESS),
    ctaFormEmailPlaceholder: t(SIGN_UP_COPY.HERO_FORM_CTA_EMAIL_ADDRESS_PLACEHOLDER),
    ctaFormNewsletterOptOut: t(SIGN_UP_COPY.HERO_FORM_CTA_NEWSLETTER_OPTOUT),
    ctaFormPassword: t(SIGN_UP_COPY.HERO_FORM_CTA_PASSWORD),
    ctaFormPasswordPlaceholder: t(SIGN_UP_COPY.HERO_FORM_CTA_PASSWORD_PLACEHOLDER),
    ctaFormPrivacyConsent: t(SIGN_UP_COPY.HERO_FORM_CTA_PRIVACY_CONSENT, {}, { html: true }),
    ctaFormSubmit: t(SIGN_UP_COPY.HERO_FORM_CTA_SUBMIT),
    disclaimer: t(SIGN_UP_COPY.HERO_FORM_CTA_DISCLAIMER),
    utSource,
    utSource2,
  };

  return (
    <>
      <EndpageHeader copy={t(PRODUCT_COPY.ENDPAGE_HEADER_COPY)} hasLogo headerColor="sabaeus" />
      <SplitHero
        utSource={utSource}
        utSource2={utSource2}
        boldHeader={boldHeader}
        subHeader={subHeader}
        formCopy={HERO_FORM_CTA}
        splitHeroTextCentered={splitHeroTextCentered}
        splitHeroDarkBackground={splitHeroDarkBackground}
        splitHeroCTA={splitHeroCTA}
        splitHeroPrimaryCTA={splitHeroPrimaryCTA}
        splitHeroSecondaryCTA={splitHeroSecondaryCTA}
        splitHeroImage={splitHeroImage}
      />
      <FortuneCallout
        showLogoWall={fortuneBannerLogoWall}
        copy={FortuneBannerCopy}
        color={fortuneBannerDarkBackground ? 'vine' : 'canvas'}
        ctaText={SIGN_UP_COPY.FORTUNE_CALLOUT_CTA}
        ctaUsage="sign-up tertiary"
        utSource={utSource}
        utSource2={utSource2}
        hideCTAs={!fortuneBannerCTA}
      />
    </>
  );
}

export default DareToAsk;
