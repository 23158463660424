import React, { useContext } from 'react';
import { StaticContext } from '@sm/webassets/StaticContext';
import { t } from '@sm/intl';
import { generateMetricsAttribute } from '@sm/metrics';
import { LogoWithText, Logo } from '@sm/webassets/Logos';
import { createURL } from '@sm/utils';
import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import { COPY } from '../SimpleLogoHeader';
import useStyles from './useStyles';

export type Props = {
  utSource: string;
  utSource2: string;
  isThanksText?: boolean;
  amplitudeEvent?: string;
  isResearchPage?: boolean;
};

const LOGO_CTA_TEXT = 'LogoWithText';
const LOGO_UT_SOURCE_3 = 'ColoredLogoWithText';

const ColoredLogoWithText = ({
  utSource,
  utSource2,
  isThanksText = false,
  amplitudeEvent,
  isResearchPage = false,
}: Props): React.ReactElement => {
  const { mainResearchBox, mainBox, researchLogo, thanksLogo, logo, customClass } = useStyles();
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);

  const link = createURL('/', {
    ut_source: utSource,
    ut_source2: utSource2,
    ut_source3: LOGO_UT_SOURCE_3,
    ut_ctatext: LOGO_CTA_TEXT,
  });

  const metrics = generateMetricsAttribute({
    data: {
      amplitudeEvent,
      itemSelected: 'logo',
      templateType,
      surveyId,
      collectorId,
      respondentId,
      log: { message: `${utSource}-${utSource2}-${LOGO_UT_SOURCE_3}-${LOGO_CTA_TEXT}` },
    },
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={isResearchPage ? mainResearchBox : mainBox}
    >
      <a
        aria-label={t(COPY.LOGO_ARIA_LABEL)}
        data-testid="ColoredLogoWithText__LogoWithText"
        href={link}
        data-sm-metrics={metrics}
      >
        {isThanksText ? (
          <Logo className={isResearchPage ? researchLogo : thanksLogo} />
        ) : (
          <LogoWithText className={`${logo} ${customClass}`} />
        )}
      </a>
      {(isThanksText || isResearchPage) && <Typography component="span">Thank you for taking this survey.</Typography>}
    </Box>
  );
};

export default ColoredLogoWithText;
