import { createUseStyles } from 'react-jss';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';
import rebrandFocusImgV1 from '~static/images/dareToAsk/slim-screenshots.webp';
import rebrandFocusImgV2 from '~static/images/dareToAsk/slim-text.webp';
import { getSrc } from '~app/helpers/assetLoading';

const useStyle = createUseStyles((theme: CmsWrenchTheme) => {
  const mediaQueryMinScreenSM = `@media (min-width: ${theme.breakpoints.sm}px)`;
  const mediaQueryMinScreenMD = `@media (min-width: ${theme.breakpoints.md}px)`;
  const mediaQueryMinScreenLG = `@media (min-width: ${theme.breakpoints.lg}px)`;
  const mediaQueryMinScreenXL = `@media (min-width: ${theme.breakpoints.xl}px)`;

  return {
    display: {
      backgroundColor: theme.brandColors.primary.vine,
    },

    wrapperV1: {
      padding: '0',
      maxWidth: '1400px',
      margin: '0 auto',
      overflowX: 'hidden',
      alignItems: 'center',
      [mediaQueryMinScreenSM]: {
        padding: '40px 60px 220px',
      },
      [mediaQueryMinScreenLG]: {
        padding: '80px 0 220px 100px',
        alignItems: 'flex-start',
      },
      [mediaQueryMinScreenXL]: {
        padding: '80px 0 220px 165px',
      },
    },

    imageV1: {
      backgroundImage: `url(${getSrc(rebrandFocusImgV1)})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      position: 'relative',
      top: '200px',
      [mediaQueryMinScreenMD]: {
        width: '550px',
        height: '433px',
        left: '150px',
      },
      [mediaQueryMinScreenLG]: {
        width: '620px',
        height: '504px',
        left: '112px',
      },
    },

    ctaFormV1: {
      margin: '0 auto',
      maxWidth: 547,
      borderRadius: [[8, 8, 0, 0]],
      padding: '24px',
      [mediaQueryMinScreenSM]: {
        borderRadius: 8,
        padding: [[48, 64, 48, 64]],
      },
      backgroundColor: '#fff',
    },

    wrapper: {
      padding: '20px 20px 50px',
      maxWidth: '1400px',
      margin: '0 auto',
      overflowX: 'hidden',
      alignItems: 'center',
      [mediaQueryMinScreenSM]: {
        padding: '40px 60px 100px',
      },
      [mediaQueryMinScreenLG]: {
        padding: '80px 0 100px 100px',
        alignItems: 'flex-start',
      },
      [mediaQueryMinScreenXL]: {
        padding: '80px 0 100px 165px',
      },
    },

    imagePlacement: {
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      position: 'relative',
      top: '70px',
      [mediaQueryMinScreenMD]: {
        width: '550px',
        height: '433px',
        left: '150px',
      },
      [mediaQueryMinScreenLG]: {
        width: '620px',
        height: '504px',
        left: '240px',
      },
    },

    image: {
      backgroundImage: `url(${getSrc(rebrandFocusImgV1)})`,
    },
    experimentImage1: {
      backgroundImage: `url(${getSrc(rebrandFocusImgV1)})`,
    },
    experimentImage2: {
      backgroundImage: `url(${getSrc(rebrandFocusImgV2)})`,
    },

    ctaForm: {
      margin: '0 auto',
      maxWidth: '380px',
    },

    header: {
      // The wrench Typography used does not allow addition of a classname
      '& > section': {
        textAlign: 'center',
        width: '100%',
        maxWidth: '1020px',
        [mediaQueryMinScreenMD]: {
          fontSize: '34px',
        },
        [mediaQueryMinScreenLG]: {
          textAlign: 'left',
          fontSize: '50px',
        },
      },
      [mediaQueryMinScreenLG]: {
        width: '100%',
      },
    },

    subHeader: {
      fontWeight: theme.type.fontWeight.regular,
      width: '100%',
      maxWidth: '1020px',
      '&, & div': {
        textAlign: 'center',
      },
      [mediaQueryMinScreenLG]: {
        maxWidth: '480px',
        '&, & div': {
          textAlign: 'left',
        },
      },
    },

    bold: {
      fontWeight: 500,
    },
  };
});

export default useStyle;
