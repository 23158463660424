import React, { useContext } from 'react';
import { StaticContext } from '@sm/webassets/StaticContext';
import { t, defineMessages } from '@sm/intl';
import { Typography } from '@wds/typography';
import { Box } from '@wds/box';
import { Grid } from '@wds/grid';
import { LogoWithText } from '@sm/webassets/Logos';
import { createURL } from '@sm/utils';
import { generateMetricsAttribute } from '@sm/metrics';
import HeroFormCTA from '~app/components/HeroFormCTA';
import heroImgV1 from '~static/images/dareToAsk/large-dark-text.webp';
import heroImgV2 from '~static/images/dareToAsk/large-light-text.webp';
import dareToAskUS from '~static/images/dareToAsk/dare-to-ask-us.gif';
import dareToAskUK from '~static/images/dareToAsk/dare-to-ask-uk.gif';
import useStyles from './useStyles';
import { COPY } from '../SimpleLogoHeader';
import HeroTextBanner from './HeroTextBanner';
import { UK_PATH_LINKS, US_PATH_LINKS } from '../constants';
import { SplitHeroProps } from '~helpers/types';
import SplitHeroButton from './SplitHeroButton';
import SplitHeroImage from './SplitHeroImage';

export const SPLIT_HERO_COPY = defineMessages({
  BUTTON_LABEL: {
    id: 'SplitHero.ButtonLabel',
    defaultMessage: 'Learn more',
    description: '[Type: label][Vis: high] - The text shown in the pages primary CTA',
  },
  IMAGE_LABEL: {
    id: 'SplitHero.ImageLabel',
    defaultMessage: 'SurveyMonkey Branding',
    description: '[Type: label][Vis: low] - The aria text shown in the pages primary image',
  },
});

const imageMap = {
  dareToAskLightText: heroImgV2,
  dareToAskUS,
  dareToAskUK,
  default: heroImgV1,
};

const SplitHero = ({
  boldHeader,
  formCopy,
  splitHeroDarkBackground = false,
  splitHeroCTA = false,
  splitHeroPrimaryCTA = '',
  splitHeroSecondaryCTA = '',
  splitHeroImage,
  splitHeroTextCentered = false,
  subHeader: subHeaderContent,
  utSource,
  utSource2,
}: SplitHeroProps): React.ReactElement => {
  const classes = useStyles();
  const utSource3 = 'SplitHero';
  const amplitudeEvent = 'endpage user interaction';
  const isUkSource = ['DareToAskPracticalUk', 'DareToAskZestyUk'].includes(utSource2);
  const isDareToAskCreate = ['DareToAskCreate'].includes(utSource2);
  const isDareToAskV3SignUp = ['DareToAskV3SignUp'].includes(utSource2);
  const imageVariant = imageMap[splitHeroImage as keyof typeof imageMap] || imageMap.default;
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);

  const primaryCtaText = splitHeroPrimaryCTA.length ? splitHeroPrimaryCTA : t(SPLIT_HERO_COPY.BUTTON_LABEL);
  const primaryUSUrl = splitHeroPrimaryCTA.length ? US_PATH_LINKS.exploreUsCtaUrl : '/mp/dare-to-ask/';
  const primaryUrl = isUkSource ? UK_PATH_LINKS?.exploreUkCtaUrl : primaryUSUrl;

  const getSecondaryUrl = (): string => {
    if (isDareToAskCreate) {
      return '/create/start';
    }
    if (isUkSource) {
      return UK_PATH_LINKS?.ukSignupUrl;
    }
    return '/sign-up';
  };

  const secondaryUrl = getSecondaryUrl();
  const getFinalUrls = (): { finalPrimaryUrl: string; finalSecondaryUrl: string } => {
    const isSwapRequired = isDareToAskCreate || isDareToAskV3SignUp;
    if (isSwapRequired) {
      return { finalPrimaryUrl: secondaryUrl, finalSecondaryUrl: primaryUrl };
    }
    return { finalPrimaryUrl: primaryUrl, finalSecondaryUrl: secondaryUrl };
  };

  const { finalPrimaryUrl, finalSecondaryUrl } = getFinalUrls();

  return (
    <>
      {splitHeroTextCentered && (
        <HeroTextBanner
          boldHeader={boldHeader}
          splitHeroDarkBackground={splitHeroDarkBackground}
          subHeader={subHeaderContent}
          utSource={utSource}
          utSource2={utSource2}
        />
      )}
      <Box
        className={splitHeroDarkBackground ? classes.darkBackground : ''}
        pt={splitHeroDarkBackground ? 10 : undefined}
      >
        <Grid noBleeds noGutters>
          <Grid.Item xl={6} lg={6} md={splitHeroCTA ? 12 : 6} sm={12} xs={12}>
            {splitHeroCTA ? (
              <Box display="flex" justifyContent="center" pt={7} pb={8} pr={4} pl={4}>
                <Box className={classes?.ctaWrapper}>
                  <a
                    aria-label={t(COPY.LOGO_ARIA_LABEL)}
                    href={createURL('/', {
                      ut_source: utSource,
                      ut_source2: utSource2,
                      ut_source3: utSource3,
                      ut_ctatext: 'LogoWithText',
                    })}
                    data-sm-metrics={generateMetricsAttribute({
                      data: {
                        amplitudeEvent,
                        itemSelected: 'logo',
                        templateType,
                        surveyId,
                        collectorId,
                        respondentId,
                        log: { message: `${utSource}-${utSource2}-${utSource3}-LogoWithText` },
                      },
                    })}
                  >
                    <LogoWithText color="alt" className={classes.logo} />
                  </a>
                  <Box className={`${classes.headerFontSize} ${classes.strangeBox}`} pt={5} pb={3}>
                    <Typography component="section" variant="hero1" color={splitHeroDarkBackground ? 'light' : 'dark'}>
                      <span className={classes.bold}>{boldHeader}</span>
                    </Typography>
                  </Box>
                  <Box pb={7} className={classes.subHeaderFontSize}>
                    <Typography
                      component="section"
                      variant="cardTitle"
                      color={splitHeroDarkBackground ? 'light' : 'dark'}
                    >
                      {subHeaderContent}
                    </Typography>
                  </Box>
                  <Box className={classes.buttonBox}>
                    <Box pr={6} pb={5}>
                      <SplitHeroButton
                        href={finalPrimaryUrl}
                        text={primaryCtaText}
                        metricsData={{ itemSelected: 'primary CTA', ut_ctatext: primaryCtaText }}
                        amplitudeEvent={amplitudeEvent}
                        templateType={templateType}
                        surveyId={surveyId}
                        collectorId={collectorId}
                        respondentId={respondentId}
                        utSource={utSource}
                        utSource2={utSource2}
                        utSource3={utSource3}
                      />
                    </Box>
                    {splitHeroSecondaryCTA?.length !== 0 && (
                      <SplitHeroButton
                        href={finalSecondaryUrl}
                        text={splitHeroSecondaryCTA}
                        metricsData={{ itemSelected: 'secondary CTA', ut_ctatext: splitHeroSecondaryCTA }}
                        variant="ghost"
                        amplitudeEvent={amplitudeEvent}
                        templateType={templateType}
                        surveyId={surveyId}
                        collectorId={collectorId}
                        respondentId={respondentId}
                        utSource={utSource}
                        utSource2={utSource2}
                        utSource3={utSource3}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box p={3}>
                <HeroFormCTA className={classes.ctaForm} utSource={utSource} utSource2={utSource2} {...formCopy} />
              </Box>
            )}
          </Grid.Item>
          <Grid.Item xl={6} lg={6} md={splitHeroCTA ? 12 : 6} sm={12} xs={12}>
            <SplitHeroImage
              imageVariant={imageVariant as string}
              utSource={utSource}
              utSource2={utSource2}
              utSource3={utSource3}
              amplitudeEvent={amplitudeEvent}
              surveyId={surveyId}
              collectorId={collectorId}
              respondentId={respondentId}
              imagePlacement={classes.imagePlacement}
              imageAlt={t(SPLIT_HERO_COPY.IMAGE_LABEL)}
            />
          </Grid.Item>
        </Grid>
      </Box>
    </>
  );
};

export default SplitHero;
