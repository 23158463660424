import { createUseStyles } from 'react-jss';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';

const useStyles = createUseStyles(({ type, breakpoints, spacing, brandColors }: CmsWrenchTheme) => {
  const mediaQueryMinScreenMD = `@media (min-width: ${breakpoints.md}px)`;
  const mediaQueryMinScreenSM = `@media (min-width: ${breakpoints.sm}px)`;

  const commonLogoStyles = {
    fontSize: type.fontSize.cardTitle,
    alignSelf: 'center',
    paddingRight: spacing[2],
  };

  return {
    mainBox: {
      width: '100%',
      height: '48px',
      '& span': {
        fontSize: type.fontSize.bodySm,
        color: brandColors.neutral.black,
        fontWeight: 500,
      },
    },
    mainResearchBox: {
      width: '100%',
      height: '48px',
      background: brandColors.primary.vine,
      color: brandColors.neutral.white,
      [mediaQueryMinScreenMD]: {
        height: '93px',
      },
      '& span': {
        fontSize: type.fontSize.bodySm,
        color: brandColors.neutral.white,
        fontWeight: 500,
        [mediaQueryMinScreenMD]: {
          fontSize: type.fontSize.cardTitle,
        },
      },
    },

    logo: {
      height: '24px',
      fontWeight: '500',
      textAlign: 'center',
    },
    thanksLogo: {
      ...commonLogoStyles,
      '& svg': {
        '& g': {
          color: brandColors.primary.heritageGreen,
          fill: brandColors.primary.heritageGreen,
        },
      },
    },
    researchLogo: {
      ...commonLogoStyles,
      [`${mediaQueryMinScreenSM}`]: {
        fontSize: (type.fontSize.cardTitle as number) + 4,
      },
      '& svg': {
        '& g': {
          color: brandColors.neutral.white,
          fill: brandColors.neutral.white,
        },
      },
    },
    // Set custom styles to force the LogoWithText to the current Logo Coloring
    customClass: {
      '& div span a': {
        color: brandColors.primary.cleanSlate,
      },
      '& g': {
        color: brandColors.primary.cleanSlate,
        fill: brandColors.primary.cleanSlate,
      },
    },
  };
});

export default useStyles;
